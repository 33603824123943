<template>
  <div justify="center">
    <v-col cols="12">
      <v-snackbar v-model="snackbar">
        {{ snack }}
        <template v-slot:action="{ attrs }">
          <v-btn color="pink"
                 text
                 v-bind="attrs"
                 @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog v-model="dialog"
                persistent
                max-width="290">
        <v-card-title class="text-h5">
          Use Google's location service?
        </v-card-title>
        <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even
          when no apps are running.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1"
                 text
                 @click="confirm = false">
            Disagree
          </v-btn>
          <v-btn color="green darken-1"
                 text
                 @click="confirm = false">
            Agree
          </v-btn>
        </v-card-actions>


      </v-dialog>





      <v-card class="pa-2"
              tile>
        <v-progress-linear indeterminate
                           color="green"
                           v-if="!users.length"></v-progress-linear>

        <v-container>
          <v-row>
            <v-col cols="12"
                   sm="6">
              <v-text-field v-model="search"
                            label="Recherche une license"
                            clearable
                            type="search"
                            filled
                            rounded
                            append-icon="mdi-filter"></v-text-field>
            </v-col>
            <v-col cols="12"
                   sm="6">
              <v-btn @click="dialogManager = true; action = 'new'"
                     elevation="3"
                     class="pa-2"
                     dark
                     rounded
                     large
                     color="green">
                <v-icon>mdi-plus</v-icon> Ajouter une license
              </v-btn>
            </v-col>

          </v-row>
        </v-container>

        <v-dialog v-model="dialogManager"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition">

          <v-card class="pa-2">
            <v-toolbar dark
                       color="primary">
              <v-btn icon
                     dark
                     @click="dialogManager = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ action == 'new' ? "Ajouter une license" : "Editer cette license"}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark
                       text
                       @click="dialogManager = false">
                  Fermer
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-form v-model="valid">
              <v-container>
                <v-row>
                  <v-col cols="12"
                         md="3">
                    <v-text-field v-model="user.nom"
                                  label="Nom"
                                  filled
                                  append-icon="mdi-pencil"
                                  required></v-text-field>
                  </v-col>

                  <v-col cols="12"
                         md="3">
                    <v-text-field v-model="user.prenom"
                                  label="Prénom"
                                  filled
                                  append-icon="mdi-pencil"
                                  required></v-text-field>
                  </v-col>

                  <v-col cols="12"
                         md="3">
                    <v-text-field v-model="user.entreprise"
                                  label="Nom de l'entreprise"
                                  filled
                                  append-icon="mdi-pencil"
                                  required></v-text-field>
                  </v-col>

                  <v-col cols="12"
                         md="3">
                    <v-text-field v-model="user.email"
                                  :rules="rules.email"
                                  label="E-mail"
                                  filled
                                  append-icon="mdi-email"
                                  required></v-text-field>
                  </v-col>
                  <v-col cols="12"
                         md="3">
                    <v-text-field v-model="user.idDevice"
                                  type="text"
                                  label="Id Device"
                                  filled
                                  append-icon="mdi-lock"
                                  required></v-text-field>
                  </v-col>
                  <v-col cols="12"
                         md="3">
                    <v-text-field v-model="user.password"
                                  :rules="rules.pass"
                                  type="password"
                                  label="Mot de passe"
                                  filled
                                  append-icon="mdi-lock"
                                  required></v-text-field>
                  </v-col>

                  <v-col cols="12"
                         md="3">
                    <v-text-field type="password"
                                  v-model="user.repass"
                                  :rules="rules.pass"
                                  label="Confirmation de mot de passe"
                                  filled
                                  append-icon="mdi-lock"
                                  required></v-text-field>
                  </v-col>

                  <v-col cols="12"
                         md="3">
                    <v-date-picker filled
                                   :rules="rules.required"
                                   append-icon="mdi-calendar"
                                   label="Date d'expiration"
                                   required
                                   v-model="user.dateExpired"
                                   elevation="15"
                                   full-width
                                   class="mt-4"></v-date-picker>
                    <v-text-field v-model="user.dateExpired"
                                  label="Date d'expiration"
                                  hint="DD/MM/YYYY"
                                  persistent-hint
                                  prepend-icon="mdi-calendar"
                                  @blur="date = parseDate(dateFormatted)"></v-text-field>
                  </v-col>


                  <v-col cols="12"
                         md="4">
                    <v-text-field v-model="user.trial"
                                  :rules="rules.required"
                                  type="number"
                                  label="Nb de jour d'essai"
                                  filled
                                  append-icon="mdi-day"
                                  required></v-text-field>
                  </v-col>

                  <v-col cols="12"
                         md="3">
                    <v-switch v-model="user.statut"
                              inset
                              :label="`Le compte est t-il actif sur Firebase Auth ?`"></v-switch>
                  </v-col>

                  <v-col>
                    <v-card cols="12"
                            md="12"
                            dark
                            color='primary'
                            class="pa-2">
                      <v-card-title>Génération de Token</v-card-title>

                      <v-text-field readonly
                                    filled
                                    label="Token"
                                    outlined
                                    v-model="user.token"></v-text-field>
                      <v-btn color="orange"
                             dark
                             @click="rand">
                        <v-icon left
                                dark>mdi-refresh</v-icon>Regénéer une clef d'activation
                      </v-btn>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-btn block
                           @click=" action == 'new' ? addLicense() : editLicense()"
                           :color='action == "new" ?  "primary" : "green"'
                           dark
                           large>
                      <v-icon left>
                        mdi-check
                      </v-icon>
                      {{ action == 'new' ? "Ajouter une license" : "Editer cette license"}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-dialog>

        <v-simple-table v-if="users.length">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">

                </th>


                <th class="text-left">
                  Token
                </th>

                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Email
                </th>
                <th class="text-left">
                  Entreprise
                </th>
                <th class="text-left">
                  Modules
                </th>
                <th class="text-left">
                  Etat
                </th>
                <th class="text-left">
                  Date Expiration
                </th>
                <th class="text-left">
                  Dernière connexion
                </th>
                <th class="text-left">
                  Id du Device
                </th>
                <th class="text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in usersFilter"
                  :key="user.id">
                <td>
                  <v-btn class="mx-2"
                         fab
                         dark
                         small
                         outlined
                         color="primary"
                         @click="edit(user.uid); action='edit'">
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </td>
                <td><small>
                    <v-chip label
                            color='primary'>{{ user.token }}</v-chip>
                  </small></td>
                <td class="h3"><strong>{{ user.nom }} {{ user.prenom }}</strong></td>
                <td><a :href="`mailto:${user.email}`">{{ user.email }}</a></td>
                <td>{{ user.entreprise }}</td>
                <td>
                 
                <v-checkbox
                v-model="user.modules"
                :label="`Inventaire`"
                multiple 
                value="inventaire"
                :checked="user.modules && user.modules.includes('inventaire')"
                @change="modules( user,user.modules, 'inventaire')"
              ></v-checkbox>
              <v-checkbox
                v-model="user.modules"
                multiple 
                :label="`Cubage`"
                value="cubage"
                :checked="user.modules && user.modules.includes('cubage')"
                 @change="modules( user, user.modules, 'cubage')"
              ></v-checkbox>
              <v-checkbox
                v-model="user.modules"
                multiple 
                :label="`Comptage`"
                value="comptage"
                :checked="user.modules && user.modules.includes('comptage')"
                 @change="modules( user,user.modules, 'comptage')"
              ></v-checkbox>


                </td>
                <td>
                  <v-icon v-if="!user.statut"
                          large
                          color="red darken-2">
                    mdi-alert
                  </v-icon>

                  <v-icon v-else
                          large
                          color="green darken-2">
                    mdi-play
                  </v-icon>
                </td>
                <td>
                  <v-chip class="ma-2"
                          color="danger"
                          outlined>
                    <v-icon left>
                      mdi-clock
                    </v-icon>
                    {{ formatDate(user.dateExpired) }}
                  </v-chip>
                </td>
                <td>
                  <v-chip class="ma-2"
                          dark
                          :color="!user.metadata.lastRefreshTime ? 'red' : 'green'"
                          outlined>
                    <v-icon left>
                      mdi-clock
                    </v-icon>
                    {{ user.metadata.lastRefreshTime ? formatDate(user.metadata.lastRefreshTime) : 'Pas encore connecté' }}
                  </v-chip>
                </td>
                <td>
                  <v-chip class="ma-2"
                          dark
                          color="blue">
                    <v-icon left>
                      mdi-phone
                    </v-icon>
                    {{ user.idDevice ? user.idDevice : 'Aucun device' }}
                  </v-chip>
                </td>

                <td>
                  <v-btn class="ma-1"
                         small
                         depressed
                         color="primary"
                         @click="enable(user.uid)"
                         v-if="!user.statut">
                    <v-icon left
                            dark>
                      mdi-check-circle
                    </v-icon>
                    Activer
                  </v-btn>

                  <v-btn class="ma-1"
                         small
                         depressed
                         color="red"
                         outlined
                         @click="disable(user.uid)"
                         v-if="user.statut">
                    <v-icon left
                            dark>
                      mdi-backspace
                    </v-icon>

                    Désactiver
                  </v-btn>
                </td>
              </tr>

            </tbody>
          </template>
        </v-simple-table>

      </v-card>


      <v-card class="pa-2"
              tile>

        <v-container>
          <v-row>
            <v-col cols="12"
                   sm="6">
              <v-text-field v-model="license"
                            label="Token une license"
                            clearable
                            filled
                            rounded
                            append-icon="mdi-check"></v-text-field>
            </v-col>
            <v-col cols="12"
                   sm="6">
              <v-btn @click="verify"
                     elevation="3"
                     class="pa-2"
                     dark
                     rounded
                     color="primary">
                <v-icon>mdi-check</v-icon> Verifier une license
              </v-btn>
            </v-col>

          </v-row>
          <v-alert dismissible
                   border="left"
                   colored-border
                   :type="verifyRes === false ? 'error': 'success'"
                   v-if="verifyRes !== ''"
                   dark
                   :color=" verifyRes === false ? 'red' : 'green'">
            {{ verifyRes === false ? 'License non valide' : 'License valide' }}
          </v-alert>

        </v-container>
      </v-card>
    </v-col>
  </div>
</template>


<script>
import xhr from "../plugins/axios";
import moment from "moment";
moment.locale("fr");
import { mask } from "vue-the-mask";
const dt = new Date();

export default {
  name: "home",
  directives: { mask },
  components: {
  },
  data(vm) {
    return {
      licenses: [],
      license: '',
      verifyRes: '',
      valid: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      headers: [
          {
            text: 'UID',
            align: 'start',
            sortable: true,
            value: 'uid',
          },
          { text: 'Name', value: 'firstname' },
          { text: 'Email', value: 'email' },
          { text: 'Entreprise', value: 'entreprise' },
          { text: 'Etat',  sortable: false, },
          { text: 'Date Expriration', value: 'dateExpired' },
          // { text: 'Trial (jour)', value: 'trial' },
          {
            text: 'Action',
            align: 'end',
            sortable: false,
          },
      ],
      user: {
        nom: "",
        prenom: "",
        email: "",
        password: "",
        idDevice: "",
        entreprise: "",
        statut: true,
        token: "",
      },
      rules: {
        required: (value) => !!value || "Obligatoire.",
        min: (value) => (value && value.length > 3) || "Pas assez long",
        password: (value) => (value && value.length > 7) || "Pas assez long",
        email: (value) => email.test(value) || "E-mail non valide",
        long: (value) => long.test(value) || "Non valide",
        repass: (value) =>
          (value && value.length > 7 && this.manager.password == value) ||
          "Confirmation non valide",
      },
      action: "new",
      search: '',
      dialogManager: false,
      snackbar: false,
      dialog: false,
      snack: "Votre compte a bien été activé",
      confirm: false,
      users : [],
      accessToken: "pk.eyJ1IjoibWFudWVsdGFpd2EiLCJhIjoiY2w0c2xzZml1MDUzejNpbzYwd294NDZycCJ9.ELDGOASK0iPzLZmDkWzAlg", // your access token. Needed if you using Mapbox maps
      mapStyle: 'mapbox://styles/mapbox/dark-v10', // your map style
      coordinates: [ 4.834277, 45.763420],
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Object,
        default: () => {}
      },
      areaData: {
        datasets: [
              {fill: 'origin'},      // 0: fill to 'origin'
              {fill: '+2'},          // 1: fill to dataset 3
              {fill: 1},             // 2: fill to dataset 1
              {fill: false},         // 3: no fill
              {fill: '-2'},          // 4: fill to dataset 2
              {fill: {value: 25}}    // 5: fill to axis value 25
          ]
      },
      chartData: {
          labels: [ 'January', 'February', 'March' ],
          datasets: [{
            label: 'My First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(201, 203, 207, 0.2)'
            ],
            borderColor: [
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
              'rgb(201, 203, 207)'
            ],
            borderWidth: 1
          }]
      },
      chartOptions: {
          responsive: true
      }
    };
  },
  mounted() {
    // this.$refs.calendar.checkChange()
  },
  methods: {
    async modules(user, mods, type){
      let userUpdated = await xhr.post("/user/modules-update", {
        uid: user.uid,
        modules: mods
      })
      console.log(userUpdated, "userUpdated");
    
    },

    async verify(){
      console.log(this.license)
      let res = await xhr.post("/user/isActivate", {
        token: this.license
      })
      this.verifyRes = res.data
    },
    async edit(uid){
      this.action = "edit"
      let user = await xhr.post("/user/load", {
        uid: uid
      })
      user = user.data
      this.user = user
      this.user.dateExpired = moment(user.dateExpired).format("YYYY-MM-DD")
      this.dialogManager = true
    },
    async rand(){
        this.user.token = this.random()
    },
    async addLicense(){
      await xhr.post("/user/add", {
        user: this.user
      })
      await this.loading()
      this.dialogManager = false
    },
    async editLicense(){
      await xhr.post("/user/edit", {
        user: this.user,
      })
      await this.loading()
      this.dialogManager = false
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${day}/${month}/${year}`
    },
    random(){
      return Math.random().toString(36).slice(2)
    },
    async filterOnlyCapsText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    async loading(){
      let tab = await xhr.get("/users")
      this.users = Object.values(tab.data)
    },
    async enable(uid){
       this.snack = "Ce compte a bien été activé"
      await xhr.post("/user/enable", {uid})
      this.snackbar = true
      await this.loading()
    },
   async disable(uid){
      this.snack = "Ce compte a bien été desactivé"
      await xhr.post("/user/disable", {uid})
      this.snackbar = true
      await this.loading()
    },
    formatDate(datIn){
      return moment(datIn).format("DD/MM/YYYY")
    },
     async onMapLoaded(event) {
      // Here we cathing 'load' map event
//       const asyncActions = event.component.actions
//  // in component
//       this.map = event.map;
//       // this.map.on('load', () => {
//       this.map.addSource('maine', {
//         'type': 'geojson',
//         'data': {
//         'type': 'Feature',
//         'geometry': {
//         'type': 'Polygon',
//         // These coordinates outline Maine.
//         'coordinates': [
//         [
//           [4.834262, 45.763410],
//           [4.834282, 45.763450],
//           [4.834282, 45.763417],
//           [4.844282, 45.763317],
//           [4.824272, 45.753405],
//           ]
//         ]
// }
// }
// })

//     this.map.addLayer({
//       'id': 'maine',
//       'type': 'fill',
//       'source': 'maine', // reference the data source
//       'layout': {},
//       'paint': {
//       'fill-color': '#0080ff', // blue color fill
//       'fill-opacity': 0.5
//       }
//     });

      // Add the vector tileset as a source.
    // })
    // const newParams = await asyncActions.flyTo({
    //   center: [ 4.834277, 45.763420],
    //   zoom: 13,
    //   speed: 1
    // })
    // console.log(newParams)
    /* => {
            center: [30, 30],
            zoom: 9,
            bearing: 9,
            pitch: 7
          }
    */
  },
  },
  watch: {
    ["user.modules"](val){
      console.log(val, "user modules");
    },
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  computed: {

  computedDateFormatted () {
      return this.formatDate(this.date)
    },

  usersFilter(){
    if(this.search.length > 2){
      let reg = new RegExp(this.search, "ig")
      return this.users.filter((elt) => reg.test(elt.email) || reg.test(elt.nom) || reg.test(elt.prenom) || reg.test(elt.uid) | reg.test(elt.trial) || reg.test(elt.token))
    }
    return this.users;
  },
  popupCoordinates() {
      // Here we can do some work for calculate proper coordinates
      //...
      return  [ 4.8342778, 45.763421]
    }
  },
  async created() {
    let lic = await xhr.get("/users/load")
    this.licenses = lic.data

    await this.loading()
    this.user.token = this.random()
  },
};
</script>